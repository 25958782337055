<template>
  <v-app>
    <v-navigation-drawer app v-model="drawer">
      <v-flex class=" d-flex justify-center" style="height: 150px">
        <v-img contain max-width="200" :src="renderLogoImage" alt="NovaXS" />
      </v-flex>
      <v-flex>
        <v-divider></v-divider>
      </v-flex>
      <v-flex>
        <v-list
            nav dense
        >
          <v-list-item
              to="/dashboard"
              active-class="white--text primary darken-1"
          >
            <v-list-item-icon>
              <v-icon>dashboard</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Dashboard</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
              to="/list-updates"
              active-class="white--text primary darken-1"
          >
            <v-list-item-icon>
              <v-icon>system_update_alt</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Atualizações</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
              to="/list-logs"
              active-class="white--text primary darken-1"
          >
            <v-list-item-icon>
              <v-icon>text_snippet</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Logs</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-flex>
      <template v-slot:append>
        <div class="pa-2">
          <v-btn color="primary" @click="logout" block>Sair</v-btn>
        </div>
      </template>
    </v-navigation-drawer>

    <v-app-bar color="primary" app>
      <v-app-bar-nav-icon color="white" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <v-btn icon @click="logout()">
        <v-icon color="white">exit_to_app</v-icon>
      </v-btn>
    </v-app-bar>

    <!-- Sizes your content based upon application components -->
    <v-main>

      <!-- Provides the application the proper gutter -->
      <v-container fluid grid-list-sm class="pa-0">

        <!-- If using vue-router -->
        <router-view ></router-view>
      </v-container>
    </v-main>

  </v-app>
</template>

<script>
import UserService from "../services/UserService";
export default {
  data: function() {
    return {
      hackDosComputed: 0,
      drawer: true
    }
  },
  mounted() {
  },
  computed: {
    renderLogoImage: function() {
      return require("../assets/logo.png");
    },
  },
  methods: {
    logout() {
      UserService.logout(() => {
        this.$router.push("/login");
      });
    }
  }
}
</script>
<style>
.aaa{
  overflow-y: hidden;
}
</style>